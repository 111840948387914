import React from "react";
import _ from "lodash";
import Expertise from '../components/Expertise'
import Icon from '../components/Icon';
import IllustrationPair from '../media/illustration/Pair.png';

export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'data-transformation',
      title: "Akimed - Transformer et explorer vos données de santé",
      description: "Transformer, améliorer la qualité, et renforcer vos données de santé vers un common data model comme OMOP ou FHIR pour accélérer l'exploration et la reproductivité de vos recherches obervationnelles.",
      content:
        <div class="columns section-panel">
            <div class="column alt-column is-8">
                <div class="content is-medium">
                  <div class="tags are-medium">
                    <span class="tag">Real world evidences</span>
                    <span class="tag">Data engineering</span>
                    <span class="tag">Data science</span>
                    <span class="tag">OMOP</span>
                    <span class="tag">FHIR</span>
                  </div>
                  <p>Travailler dans la technologie en santé c’est devoir appréhender deux mondes&nbsp;: le <strong>monde médical</strong> avec une ontologie particulièrement complexe et le <strong>monde technologique</strong> avec son lot de concepts qui évoluent très rapidement.</p>
                  <p>Akimed joue le rôle de <strong>traducteur</strong> entre ces deux mondes grâce à son équipe pluridisciplinaire composée de <strong>médecins</strong> et d’<strong>ingénieurs</strong> qui travaillent ensemble pour transformer la donnée médicale en une donnée abordable par les acteurs de la santé.</p>
                  <p>Nous avons développé de nombreux algorithmes permettant de réaliser ces traductions (ETL dant le jargon informatique) vers des formats standards comme OMOP ou FHIR.</p>
                  <p>Quelques exemples de cas d'études :</p>
                  <ul>
                    <li>Mapping de vos terminologies vers des standards comme SNOMED, RxNorm ou LOINC</li>
                    <li>Structuration du format des données vers les normes FHIR, OMOP ou CDISC</li>
                    <li>Mise en place de pipeline de transformation automatique sur le cloud.</li>
                  </ul>
                </div>
            </div>
            <div class="column alt-column is-4">
                <div class="content is-medium">
                <figure class="image">
                    <Icon src={IllustrationPair} />
                </figure>
                </div>
            </div>
        </div>
    };
  }

  render() {
    return (
      <Expertise 
        content={this.state.content}
        activePage={this.state.page} 
        title={this.state.title} 
        description={this.state.description}
      />
    );
  }
}
